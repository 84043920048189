<template>
  <div
    v-if="visibleBanners.length && showPromoBanners"
    class="promo-banner"
    :class="{ active: bannerContent }"
  >
    <Transition name="fade">
      <div
        v-if="bannerContent"
        :key="bannerIndex"
        :style="`--backgroundColor: ${bannerContent.BackgroundColor}; --color: ${bannerContent.Color};`"
      >
        <div
          class="promo-banner__item"
        >
          <StrapiBlocksText
            v-if="bannerContent?.Text?.length"
            :nodes="bannerContent.Text"
          />
        </div>

        <button
          type="button"
          class="promo-banner__close"
          @click="closePromoBanner()"
        >
          <IconClose />
        </button>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import layoutQuery from '~layers/app/graphql/layout.gql'

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

const promoBanners = computed(() => {
  return data?.value?.promoBanner?.PromoBanners?.filter((banner) => {
    let x = true
    if (banner.DateFrom && banner.DateFrom > new Date().toISOString()) {
      x = false
    }
    if (banner.DateTo && banner.DateTo < new Date().toISOString()) {
      x = false
    }
    return x
  })?.map((banner) => {
    return {
      ...banner,
      Text: JSON.parse(JSON.stringify(banner.Text).replace('{dynamic-delivery-promise}', deliveryPromise.value.content || '')),
    }
  }) || []
})

const showPromoBanners = useSessionStorage('show_promo_banners', true, { initOnMounted: true })

const visibleBanners = computed(() => {
  return promoBanners.value
})

const interval = ref(10000)
const bannerContent = ref()
const bannerIndex = ref(0)
let i = 0

const setActiveBanner = () => {
  bannerContent.value = visibleBanners.value[i]
  bannerIndex.value = i++

  i = i > visibleBanners.value.length - 1 ? 0 : i
}

const { isActive } = useIntervalFn(() => {
  if (isActive.value) {
    setActiveBanner()
  }
}, interval)

function closePromoBanner() {
  showPromoBanners.value = !showPromoBanners.value
}

const day = data?.value?.dayOfWeek
const deliveryPromise = computed(() => {
  const dpKey = Object.keys(data?.value?.deliveryPromise || {}).find(key => key.includes(`_${day}`))

  if (!dpKey || !data?.value?.deliveryPromise[dpKey]) {
    return ''
  }

  const [name, key] = dpKey.split('_')

  return {
    name,
    key,
    content: data?.value?.deliveryPromise[dpKey],
  }
})

onMounted(() => {
  setActiveBanner()
})
</script>

<!-- <style src="~layers/app/components/App/PromoBanner.css" /> -->
<style>
@import url("~layers/app/components/App/PromoBanner.css");
</style>
