<template>
  <div
    class="layout layout--default"
    :class="template"
  >
    <a
      class="visually-hidden"
      href="#main"
    >
      {{ $t('Skip to content') }}
    </a>

    <AppNotification />

    <AppPromoBanner class="layout__promo-banner" />

    <div class="header__top">
      <div class="container header__top-container">
        <AppUsps
          v-if="data?.headerUsps?.[0]?.Usp?.length"
          class="header__usps"
          :usps="data.headerUsps[0].Usp"
          horizontal
        />

        <ThirdpartyEtrustedEmbed
          v-if="config.trustedShopsWidget"
          class="header__etrusted"
          :widget-id="config.trustedShopsWidget"
        />

        <AppLink
          class="header__thuiswinkel-waarborg"
          to="https://www.thuiswinkel.org/leden/travelbags-nl/certificaat/"
          target="_blank"
        >
          <IconLabelsThuiswinkelWaarborg
            class="icon icon--thuiswinkel-waarborg"
            filled
          />
        </AppLink>
      </div>
    </div>

    <AppHeader class="layout__header" />

    <main class="layout__main">
      <slot />
    </main>

    <AppFooter class="layout__footer" />

    <CartAdded />
    <CartRestore />

    <!--  -->
  </div>
</template>

<script lang="ts" setup>
import layoutQuery from '~layers/app/graphql/layout.gql'

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

const template = ''
useKiosk()

// const header = ref(null)

// useResizeObserver(header, (entries) => {
//   const entry = entries[0]
//   const { width, height } = entry.contentRect
//   // text.value = `width: ${width}, height: ${height}`
//   console.log({ width, height })
// })

// const { stop } = useIntersectionObserver(
//   target,
//   ([{ isIntersecting }], observerElement) => {
//     targetIsVisible.value = isIntersecting
//   },
// )
</script>

<style src="~layers/app/layouts/default.css" />
