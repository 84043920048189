<template>
  <div class="navigation__header">
    <span class="visually-hidden">{{ $t('Menu') }}</span>

    <AppLogo
      class="navigation__logo"
      color="black"
    />

    <button
      class="navigation__close button__close"
      :aria-label="$t('Close')"
      @click="closeNavigation"
    >
      <IconClose class="icon icon--close" />
      <span class="visually-hidden">{{ $t('Close') }}</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
const {
  closeNavigation,
} = useNavigation()
</script>

<!-- <style src="~layers/app/components/Navigation/Header.css" /> -->
<style>
@import url("~layers/app/components/Navigation/Header.css");
</style>
