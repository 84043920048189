<template>
  <div
    class="subnavigation"
    :class="{ 'subnavigation--open': index === showSubmenu }"
  >
    <div class="subnavigation__container container">
      <button
        type="button"
        class="subnavigation__back"
        @click="toggleSubnavigation(index, item, $event)"
      >
        <span>{{ $t('Back') }}</span>
      </button>

      <AppLink
        :to="item.Link"
        class="subnavigation__title"
      >
        <span>{{ item.AllTitle || $t('All') + ' ' + item.Title }}</span>
      </AppLink>

      <div
        v-if="item?.Column1?.length"
        class="subnavigation__links"
      >
        <StrapiBlocksText :nodes="item.Column1" />
      </div>

      <div
        v-if="item?.Column2?.length"
        class="subnavigation__links"
      >
        <StrapiBlocksText :nodes="item.Column2" />
      </div>

      <div
        v-if="item?.Column3?.length"
        class="subnavigation__links"
      >
        <StrapiBlocksText :nodes="item.Column3" />
      </div>

      <div
        v-if="item?.Banner1"
        class="subnavigation__impression "
      >
        <NavigationBanner :banner="item.Banner1" />
      </div>

      <div
        v-if="item?.Banner2"
        class="subnavigation__impression"
      >
        <NavigationBanner :banner="item.Banner2" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  item: any
  index: number
}>()

const {
  showSubmenu,
  toggleSubnavigation,
} = useNavigation()
</script>

<!-- <style src="~layers/app/components/Navigation/Level2.css" /> -->
<style>
@import url("~layers/app/components/Navigation/Level2.css");
</style>
