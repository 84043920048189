<template>
  <div class="newsletter">
    
<FormKitLazyProvider>
<div class="newsletter__heading heading--6">
      {{ $t('Newsletter') }}
    </div>

    <FormKit
      v-if="!confirmed"
      id="newsletter__form"
      name="newsletter__form"
      type="form"
      :incomplete-message="$t('Sorry, not all fields are filled out correctly.')"
      form-class="newsletter__form"
      :actions="false"
      :config="{ }"
      @submit="subscribeToNewsletter"
    >
      <p>
        {{ $t('Receive the best offers and personal advice.') }}
      </p>

      <FormKit
        id="newsletter__email"
        v-model="email"
        type="email"
        name="email"
        :data-label="$t('Email address') + ' *'"
        :placeholder="$t('Email address')"
        :disabled="subscribeToNewsletterLoading || confirmed"
        validation="required|email"
        :validation-messages="{
          required: $t('Email address is a required field.'),
          email: $t('Email address is invalid.'),
        }"
      />

      <FormKitMessages hidden />

      <div class="newsletter__agree">
        <UiRichtext :html="$t('By subscribing you accept our terms & conditions')" />
      </div>

      <div class="formkit-buttons">
        <button
          type="submit"
          class="newsletter__button button button--cta button--center"
        >
          <span>{{ $t('Subscribe') }}</span>
        </button>
      </div>
    </FormKit>

    <div v-else>
      <p>
        {{ $t('Thank you very much! You will receive an email from us within a few minutes.') }}
      </p>
    </div>
</FormKitLazyProvider>

  </div>
</template>

<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
const emit = defineEmits(['subscribed'])

const { newsletterSubscription } = useDataLayer()
const { email, confirmed, confirm, subscribe, subscribeToNewsletterLoading } = useNewsletter()

const subscribeToNewsletter = async (/* $data: any */) => {
  subscribe.value = true
  const result = await confirm()

  if (result) { // success ?
    newsletterSubscription(email.value)
    emit('subscribed')
  }
}
</script>

<!-- <style src="~layers/app/components/App/Newsletter.css" /> -->
<style>
@import url("~layers/app/components/App/Newsletter.css");
</style>
