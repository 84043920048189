<template>
  <div class="mini-wishlist">
    <AppLink
      to="/wishlist/"
      class="mini-wishlist__link"
      :aria-label="$t('My wishlist')"
    >
      <IconHeartEmpty class="mini-wishlist__icon icon icon--wishlist" />
      <span
        class="mini-wishlist__amount"
      >
        {{ count }}
      </span>
      <span class="mini-wishlist__label">
        {{ $t('My wishlist') }}
      </span>
    </AppLink>
  </div>
</template>

<script setup lang="ts">
import wishlistProductQuery from '~layers/app/graphql/wishlist/product.gql'
import type { EsProduct } from '~layers/app/types/elastic'

const { count, latest } = useWishlist()
const { addToWishList } = useDataLayer()
watch(latest, async () => {
  const { data } = await useAsyncQuery<{ product: EsProduct }>(
    wishlistProductQuery,
    {
      sku: latest.value?.sku,
    },
  )
  addToWishList(data.value?.product)
})
</script>

<!-- <style src="~layers/app/components/App/MiniWishList.css" /> -->
<style>
@import url("~layers/app/components/App/MiniWishList.css");
</style>
