<template>
  <nav
    v-if="data?.mainMenu?.Items?.length"
    :class="{ 'navigation--open': open }"
    class="navigation"
    aria-label="Main"
  >
    <Html :class="{ 'navigation--open': open }" />

    <NavigationToggle />

    <div
      id="navigation"
      class="navigation__panel"
    >
      <NavigationHeader />

      <div
        class="navigation__content"
      >
        <slot name="navigation_before" />

        <ul
          class="navigation__list"
        >
          <template v-for="(item, index) in data.mainMenu.Items">
            <li
              v-if="item?.Title && item?.Link && item?.ShowInNavigation"
              :key="index"
              class="navigation__item"
              @mouseenter="mouseOver(item, index)"
              @mouseleave="mouseOut"
            >
              <a
                :href="item.Link"
                class="navigation__link"
                @click.prevent="toggleSubnavigation(index, item)"
              >
                <component
                  :is="loadIcon('nav_' + item.Icon)"
                  class="navigation__icon icon"
                  :class="`icon--${item.Icon}`"
                  filled
                />
                <span>{{ item.Title }}</span>
              </a>

              <NavigationLevel2
                v-if="item.ShowSubNavigation"
                :item="item"
                :index="index"
              />
            </li>
          </template>
        </ul>
        <slot name="navigation_after" />
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import layoutQuery from '~layers/app/graphql/layout.gql'

const route = useRoute()
const router = useRouter()

const { public: config } = useRuntimeConfig()

const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })
useServerError(!data.value?.mainMenu)

const { loadIcon } = useStrapiIcons()

const {
  open,
  showSubmenu,
  closeNavigation,
  mouseOver,
  mouseOut,
  keypress,
  toggleSubnavigation,
} = useNavigation()

watch(() => route.query || route.path, () => {
  if (open.value) {
    closeNavigation()
    const mainEl = document.getElementById('main')
    if (mainEl) {
      mainEl.focus()
    }
  }
})

onMounted(() => {
  document.addEventListener('keydown', keypress)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', keypress)
  closeNavigation()
})

router.beforeEach(() => {
  showSubmenu.value = null
})
</script>

<!-- <style src="~layers/app/components/Navigation/index.css" /> -->
<style>
@import url("~layers/app/components/Navigation/index.css");
</style>
