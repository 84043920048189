<template>
  <div class="mini-cart">
    <AppLink
      :to="{ name: 'cart' }"
      class="mini-cart__link"
      :aria-label="$t('Basket')"
    >
      <IconShoppingcart class="mini-cart__icon icon icon--shoppingcart" />
      <span
        class="mini-cart__amount"
        v-text="sumQuantity ? sumQuantity : '0'"
      />
      <span
        class="mini-cart__label"
        v-text="$t('Basket')"
      />
      <span
        class="mini-cart__price"
        v-text="total"
      />
    </AppLink>

    <LazyUiDialog
      name="mini-cart-dialog"
      class="mini-cart-dialog"
      dialog-type="from-right"
    >
      <template #dialogHeader>
        {{ $t('My shopping cart') }}
      </template>

      <div v-if="items?.length">
        <div class="mini-cart__amount">
          <span>{{ $t('items', { quantity: sumQuantity }, sumQuantity) }} - </span>
          <AppLink
            to="/cart"
            data-testid="minicart-view-cart"
            @click="miniCartDialog.close"
          >
            <span>{{ $t('View cart') }}</span>
          </AppLink>
        </div>

        <div class="mini-cart__products">
          <CartLine
            v-for="item in items"
            :key="item.id"
            :item="item"
          />
        </div>

        <div class="mini-cart__total">
          <span>{{ $t('Cart subtotal') }}</span>
          <ProductPrice
            class="mini-cart__total-price"
            :prices="{ finalPrice: total }"
          />
        </div>

        <div class="dialog__buttons">
          <AppLink
            to="/cart"
            class="button button--full button--shop-flow"
            @click="miniCartDialog.close"
          >
            <span>{{ $t('View cart') }}</span>
          </AppLink>
        </div>
      </div>
      <div
        v-else
        class="mini-cart__empty"
        v-text="$t('Empty cart')"
      />
    </LazyUiDialog>
  </div>
</template>

<script lang="ts" setup>
export interface Props {
  editable?: boolean
}

withDefaults(defineProps<Props>(), { editable: false })

const { sumQuantity, total, items } = useCart()

const miniCartDialog = useUiDialog('mini-cart-dialog')
</script>

<!-- <style src="~layers/app/components/Cart/MiniCart.css" /> -->
<style>
@import url("~layers/app/components/Cart/MiniCart.css");
</style>
