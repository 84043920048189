<template>
  <LazyUiDialog
    class="added-to-cart"
    name="add-to-cart"
    dialog-type="modal"
    @close="resetAddedToCart"
    @transitionend="showSlider = true"
  >
    <template #dialogHeader>
      {{ $t('Added to cart') }}
    </template>

    <CartAddedItem
      v-for="(item, index) in addedItems"
      :key="index"
      :item="item"
      class="added-to-cart__product"
    >
      <div class="added-to-cart__product-buttons">
        <AppLink
          to="/cart"
          class="button button--cta button--full"
          data-testid="add-to-cart-view-cart"
          @click="closeDialog()"
        >
          <span>{{ $t('View cart') }}</span>
        </AppLink>
        <button
          class="button button--full"
          :disabled="hasCartItemErrors"
          @click.prevent="checkout()"
        >
          <span>{{ $t('To checkout') }}</span>
        </button>
      </div>
    </CartAddedItem>

    <ProductSlider
      v-if="showSlider && crossSellsAndRelatedProducts?.length"
      :products="crossSellsAndRelatedProducts"
      :show-cta="true"
      :custom-settings="{
        itemsToShow: 2,
        itemsToScroll: 1,
        snapAlign: 'start',
        breakpoints: {
          // 700px and up
          700: {
            itemsToShow: 2,
          },
          // 1024 and up
          1024: {
            itemsToShow: 3,
          },
        },
      }"
    >
      <span class="heading heading--6">{{ $t('Upsell products') }}</span>
    </ProductSlider>

    <div
      v-if="errors"
      class="error"
    >
      <ul>
        <li
          v-for="(error, index) in errors"
          :key="index"
        >
          <pre> {{ error }}</pre>
        </li>
      </ul>
    </div>
  </LazyUiDialog>
</template>

<script lang="ts" setup>
import type { MagentoCartItem } from '~layers/app/types/magento'

const { show: showErrorNotification } = useNotification()
const { addedToCart, items, hasCartItemErrors, errors } = useCart()
const addToCartDialog = useUiDialog('add-to-cart')
const router = useRouter()
const { addToCart } = useDataLayer()

const showSlider = ref(false)

const skus = computed(() =>
  Object.keys(addedToCart.value),
)

const addedItems = computed<MagentoCartItem[]>(() =>
  items.value.filter((item: any) => skus.value.includes(item.configured_variant?.sku || item.product.sku) && item.prices.price_including_tax.value > 0) || [],
)

const crossSellsAndRelatedProducts = computed(() =>
  addedItems.value.reduce((acc: any[], item: MagentoCartItem) => {
    return [
      ...acc,
      ...(item.product.product?.crossSellProducts || []),
      ...(item.product.product?.relatedProducts || []),
    ]
  }, []),
)

watch(addedItems, async (newVal, oldVal) => {
  if (!errors.value.length && newVal && newVal.length > 0 && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
    await addToCart(newVal.map(item => ({ ...item, quantity: addedToCart.value?.[item.configured_variant?.sku || item.product.sku] })))
    addToCartDialog.open()
  }
})

watch(errors, (newVal) => {
  if (newVal.length) {
    showErrorNotification({ type: 'error', message: newVal.join(', ') })
  }
})

const checkout = () => {
  router.push('/checkout/')
}
const closeDialog = () => {
  addToCartDialog.close()
}

const resetAddedToCart = () => {
  addedToCart.value = {}
  showSlider.value = false
}
</script>

<!-- <style src="~layers/app/components/Cart/Added.css" /> -->
<style>
@import url("~layers/app/components/Cart/Added.css");
</style>
