<template>
  <button
    type="button"
    class="navigation__toggle link"
    aria-haspopup="true"
    aria-expanded="false"
    aria-controls="navigation"
    @click="toggleNavigation"
  >
    <IconMenu class="icon icon--menu" />
    <span>{{ $t('Menu') }}</span>
  </button>
</template>

<script lang="ts" setup>
const {
  toggleNavigation,
} = useNavigation()
</script>

<!-- <style src="~layers/app/components/Navigation/Toggle.css" /> -->
<style>
@import url("~layers/app/components/Navigation/Toggle.css");
</style>
