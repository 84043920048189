<template>
  <component
    :is="getElement"
    :to="banner.File.Link"
    class="navigation-banner"
  >
    <div
      class="navigation-banner__visual"
    >
      <picture
        v-if="banner?.File?.Media?.url && banner?.File?.Media?.mime?.includes('image')"
        class="banner__picture"
      >
        <source
          :srcset="`${banner?.File?.Media?.url?.replaceAll(' ', '%20')}`"
          media="(min-width: 768px)"
        >
        <source
          :srcset="`${banner?.File?.MediaMobile?.url?.replaceAll(' ', '%20') || banner?.File?.Media?.url?.replaceAll(' ', '%20')}`"
        >
        <img
          class="navigation-banner__image"
          :src="banner?.File?.Media?.url?.replaceAll(' ', '%20')"
          :alt="banner?.File?.Media?.alternativeText || banner?.Name || ''"
          :height="banner?.File?.Media?.height"
          :width="banner?.File?.Media?.width"
          loading="lazy"
        >
      </picture>
      <div
        v-else-if="banner?.File?.Media?.url && banner?.File?.Media?.mime?.includes('video')"
        class="navigation-banner__video"
      >
        <UiVideo
          v-if="banner?.File?.Media?.url"
          :class="{ 'mq--desktop-plus': banner?.File?.Media?.url && banner?.File?.MediaMobile?.url }"
          :src="banner?.File?.Media?.url"
          :alt="banner?.File?.Media?.alternativeText || banner?.Name"
          :width="banner?.File?.Media?.width"
          :height="banner?.File?.Media?.height"
          loading="lazy"
          :controls="false"
          autoplay
          muted
          loop
        />
        <UiVideo
          v-if="banner?.File?.MediaMobile?.url && banner?.File?.MediaMobile?.mime?.includes('video')"
          class="mq--mobile-phablet"
          :src="banner?.File?.MediaMobile?.url"
          :alt="banner?.File?.MediaMobile?.alternativeText || banner?.Name"
          :width="banner?.File?.MediaMobile?.width"
          :height="banner?.File?.MediaMobile?.height"
          loading="lazy"
          :controls="false"
          autoplay
          muted
          loop
        />
      </div>
    </div>
    <div
      class="navigation-banner__content"
    >
      <div
        v-if="banner?.Title"
        class="navigation-banner__title"
      >
        {{ banner.Title }}
      </div>

      <p
        v-if="banner?.Description"
        class="navigation-banner__description"
      >
        {{ banner.Description }}
      </p>
    </div>
  </component>
</template>

<script setup lang="ts">
import { AppLink } from '#components'

const props = defineProps<{
  banner: any
}>()

const getElement = computed(() => {
  if (props?.banner?.File?.Media?.mime?.includes('video')) {
    return 'div'
  }

  if (props?.banner?.File?.Link) {
    return AppLink
  }

  return 'div'
})
</script>

<!-- <style src="~layers/app/components/Navigation/Banner.css" /> -->
<style>
@import url("~layers/app/components/Navigation/Banner.css");
</style>
