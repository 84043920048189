<template>
  <div class="service-links">
    <ul class="service-links__list">
      <li
        v-for="link in links"
        :key="link.Link"
        class="service-links__item"
      >
        <AppLink
          :to="link.Link"
          class="service-links__link"
          :style="`color: ${link.Color || 'inherit'};`"
        >
          <span>{{ link.Name }}</span>
        </AppLink>
      </li>
      <li class="service-links__item">
        <AppLink
          class="service-links__link"
          :to="{ name: 'account' }"
        >
          <span>{{ $t('My account') }}</span>
        </AppLink>
      </li>
      <li
        v-if="kioskLabel"
        class="service-links__item"
      >
        <AppLink
          class="service-links__link"
          to="/config/kiosk/"
        >
          <span>{{ kioskLabel }}</span>
        </AppLink>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  links: {
    type: Array,
    default: () => [],
  },
})

const { label: kioskLabel } = useKiosk()
</script>

<!-- <style src="~layers/app/components/Navigation/ServiceLinks.css" /> -->
<style>
@import url("~layers/app/components/Navigation/ServiceLinks.css");
</style>
