<template>
  <header
    ref="header"
    class="header"
    :class="{ 'header--sticky': isSticky, 'header--scroll-up': scrollUp }"
  >
    <Html :style="`--header-height: ${headerHeight};`" />

    <div
      ref="wrapper"
      class="header__wrapper"
    >
      <div class="container header__container">
        <AppLogo
          class="header__logo"
          data-testid="logo"
        />

        <Navigation class="header__navigation">
          <template
            v-if="data?.serviceLinks?.[0]?.Links"
            #navigation_after
          >
            <NavigationServiceLinks
              class="navigation__service-links"
              :links="data.serviceLinks[0].Links"
            />
          </template>
        </Navigation>

        <AccountMiniAccount class="header__account" />

        <CartMiniCart class="header__cart" />

        <AppMiniWishList class="header__wishlist" />

        <AppQuickSearch class="header__search" />
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import layoutQuery from '~layers/app/graphql/layout.gql'

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

const header = ref<HTMLElement | null>(null)
const wrapper = ref<HTMLElement | null>(null)
const headerHeight = ref('auto')

const isSticky = ref(false)
const scrollUp = ref(false)

const {
  searchDialogOpen,
} = useQuickSearch()

useResizeObserver(wrapper, (entries) => {
  const entry = entries[0]

  if (entry?.contentRect) {
    const { height } = entry.contentRect
    headerHeight.value = `${height}px`
  }
})

const startY = ref(0)

const handleScroll = () => {
  const scrollY = window.scrollY
  if (scrollY > startY.value && scrollY > 200) {
    scrollUp.value = false // down
    searchDialogOpen.value = false
  }
  else {
    scrollUp.value = true // up
  }
  startY.value = scrollY
}

const observer = ref<IntersectionObserver>()

onMounted(() => {
  observer.value = new IntersectionObserver(
    ([e]) => {
      if (!scrollUp.value && !e?.isIntersecting) {
        isSticky.value = true
      }

      if (scrollUp.value && e.intersectionRatio >= 0.9) {
        isSticky.value = false
      }
    },
    {
      threshold: [0, 0.95, 0.96, 0.97, 0.98, 0.99, 1],
    },
  )

  observer.value.observe(header.value!)

  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  observer.value?.unobserve(header.value!)

  window.removeEventListener('scroll', handleScroll)
})
</script>

<!-- <style src="~layers/app/components/App/Header.css" /> -->
<style>
@import url("~layers/app/components/App/Header.css");
</style>
