import quickSearchQuery from '~layers/app/graphql/quickSearch.gql'
import type { EsProduct } from '~layers/app/types/elastic'

const search = ref('')
const searchDialogOpen = ref(false)

export default function useQuickSearch() {
  // Request
  const recentSearches = useLocalStorage<string[]>('recentSearches', [], { initOnMounted: true })

  const variables = computed(() => ({
    search: search.value,
  }))
  const enabled = computed(() => search.value.length > 2)
  const { result: data, loading, error } = useQuery(quickSearchQuery, variables, {
    enabled,
    debounce: 500,
  })

  const searchRedirect = () => {
    if (!search.value) {
      return
    }
    // console.log('Search redirect', search.value)
    const router = useRouter()

    const matching = [...categories.value, ...pages.value]
      .find(({ label }: { label: string }) => label.toLowerCase() === search.value.toLowerCase())
    if (matching) {
      router.push({ path: '/' + matching.url + '/' })
    }
    else {
      router.push({ path: '/search/', query: { q: search.value } })
    }
  }

  const products = computed<EsProduct[]>(() => data.value?.products.products)
  const categories = computed(() => data.value?.categories.urls || [])
  const pages = computed(() => data.value?.pages.urls || [])

  const totalProducts = computed(() => data.value?.products.totalCount || 0)

  const hasResults = computed(() => {
    return totalProducts.value > 0 || categories.value.length > 0 || pages.value.length > 0
  })

  watch(data, () => {
    if (totalProducts.value > 0) {
      const newRecentSearches = new Set([data.value?.products.search, ...recentSearches.value])
      recentSearches.value = Array.from(newRecentSearches).slice(0, 5)
    }
  })

  return {
    data,
    search,
    searchDialogOpen,
    recentSearches,

    error,
    loading,

    hasResults,
    categories,
    pages,
    products,
    totalProducts,

    searchRedirect,
  }
}
