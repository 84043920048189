<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="footer__container container">
        <AppLogo
          color="black"
          class="footer__logo"
        />

        <AppSocials class="footer__socials" />

        <AppContact class="footer__contact" />

        <AppPaymentIcons class="footer__payment-icons" />

        <div class="footer__menu">
          <template
            v-for="(menu, index) in menus"
            :key="`${index}-${!index}-${showAll}`"
          >
            <UiCollapsable
              v-if="data?.[menu]?.[0]"
              :label="data[menu][0].Title"
              :open="!index || showAll"
              data-name="footer-menu"
            >
              <ul class="footer__menu-list">
                <li
                  v-for="(group) in data[menu][0].Links"
                  :key="group"
                >
                  <component
                    :is="getElement(group.Link)"
                    v-if="group.Name"
                    :to="group.Link"
                  >
                    {{ group.Name }}
                  </component>
                </li>
              </ul>

              <template #icon_after="{ open }">
                <IconMin
                  v-if="open"
                  class="collapsable__icon icon icon--min"
                />
                <IconPlus
                  v-else
                  class="collapsable__icon icon icon--plus"
                />
              </template>
            </UiCollapsable>
          </template>
        </div>

        <AppNewsletter class="footer__newsletter" />
      </div>
    </div>
    <div class="footer__bottom">
      <div class="footer__container container">
        <div class="footer__copyright">
          &copy; {{ $t('nuxtSiteConfig.name') }} {{ year }}
        </div>
        <div class="footer__legal">
          <nav
            v-if="data?.footerBottom?.[0]"
          >
            <strong class="visually-hidden">
              {{ data.footerBottom[0].Title }}
            </strong>
            <ul class="footer__legal-list">
              <li
                v-for="(group, index) in data.footerBottom[0].Links"
                :key="index"
              >
                <component
                  :is="getElement(group.Link)"
                  v-if="group.Name"
                  :to="group.Link"
                >
                  {{ group.Name }}
                </component>
              </li>
            </ul>

            <AppCountrySwitch />
          </nav>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import layoutQuery from '~layers/app/graphql/layout.gql'
import { AppLink } from '#components'

const { breakpoint } = useApp()

const showAll = ref(true)

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

const getElement = (to: any) => {
  return to ? AppLink : 'span'
}

const year = new Date().getFullYear()

const menus = [
  'services',
  'business',
  'stores',
]

onMounted(() => {
  watchEffect(() => {
    showAll.value = !['mobile', 'phablet', 'tablet', 'desktop'].includes(breakpoint.value)
  })
})
</script>

<!-- <style src="~layers/app/components/App/Footer.css" /> -->
<style>
@import url("~layers/app/components/App/Footer.css");
</style>
